require("es6-promise").polyfill();
require("url-polyfill");
window.axios = require("axios");

import Helpers from "../Helpers";

$(function () {
    class AppDownload {
        /**
         * Constructor Method
         */
        constructor() {
            this.helpers = new Helpers();
            this.sAppUrl = this.helpers.getAppUrl("app-download");
            this.appDownloadUnavailableAgent = ["Byapps", "smartskin", "powerapp", "makeshopapp", "app:denps", "WISAAPP"];
            this.sMallId = $("input[name=mall_id]").val();
            this.sShopNo = $("input[name=shop_no]").val();
            // add conditionals to support new app download js
            if (typeof this.sMallId === "undefined") {
                this.sMallId = EC_FRONT_JS_CONFIG_MANAGE.sMallId;
            }
            if (typeof this.sShopNo === "undefined") {
                this.sShopNo = typeof EC_SDE_SHOP_NUM !== "undefined" ? EC_SDE_SHOP_NUM : 1;
            }
            this.sAppLanguage = navigator.language.toLowerCase();
            this.sCurrentOs = null;
            this.sReferrer = document.referrer;

            this.getAppDownloadData();
        }

        /**
         * Method to get app download data
         * @returns {Promise<void>}
         */
        async getAppDownloadData() {
            let oRequest = {
                method: "GET",
                url: `${this.sAppUrl}/api/front/${this.sMallId}/app-download?shop_no=${this.sShopNo}`,
            };

            let oResponse = await this.helpers.apiRequest(oRequest);
            this.aMultiShopInfo = oResponse.data.data.multi_shop;
            this.aPlusappSettings = oResponse.data.data.plusapp_settings;
            this.sShopName = oResponse.data.data.shop_name;
            this.sDesktopErrorMessage = oResponse.data.data.desktop_error_message;
            this.sIosDisabledMessage = oResponse.data.data.disabled_ios_message;
            this.sAndroidDisabledMessage = oResponse.data.data.disabled_android_message;
            this.initialize();
        }

        /**
         * Method for initializing module
         * @returns {void|*}
         */
        initialize() {
            if (this.isEmpty(this.aMultiShopInfo) === true || this.isEmpty(this.aPlusappSettings) === true) {
                return;
            }

            if (this.isAppDownloadUnsupportedAgent() === true) {
                this.helpers.openStoreOnly(this.aPlusappSettings);
                return;
            }

            if (this.isDesktopBrowser() === true) {
                if (this.isDesktopSafari() === true) {
                    let aIosInformation = this.aPlusappSettings.ios_production_information;
                    return location.assign(`https://apps.apple.com/app/id${aIosInformation["apple_linkid"]}`);
                }

                let aAndroidInformation = this.aPlusappSettings.android_production_information;
                return location.assign(`http://play.google.com/store/apps/details?id=${aAndroidInformation["google_linkid"]}`);
            }

            if (this.checkMobileAvailable() === false) {
                let mErrorMessage = this.sCurrentOs !== null ? this.getErrorMessage(this.sCurrentOs, this.sShopName) : this.getDesktopErrorMessage(this.sDesktopErrorMessage, this.sShopName);
                alert(mErrorMessage);
                return this.closeWindow();
            }

            return this.helpers.openAppOrStore("app-download", this.sMallId, this.sAppUrl, this.aPlusappSettings);
        }

        /**
         * Method to check if mobile type is available
         * @returns {boolean}
         */
        checkMobileAvailable() {
            if (this.helpers.isAndroid() === true) {
                this.sCurrentOs = "Android";
                return this.helpers.checkOsAvailability("android_production_information", this.aPlusappSettings);
            }

            if (this.helpers.isIos() === true) {
                this.sCurrentOs = "iOS";
                return this.helpers.checkOsAvailability("ios_production_information", this.aPlusappSettings);
            }

            return false;
        }

        /**
         * Method to check if object is empty
         * @param mObject
         * @returns {boolean}
         */
        isEmpty(mObject) {
            return Object.keys(mObject).length === 0 && mObject.constructor === Object;
        }

        /**
         * Method to get desktop message
         * @param sLanguage
         * @param sShopName
         * @returns {string}
         */
        getDesktopErrorMessage(sMessage, sShopName) {
            var sErrorMessage = sMessage.replace("{$쇼핑몰명}", sShopName);

            return sErrorMessage;
        }

        /**
         * Method for generating error message
         * @param sLanguage
         * @param sOS
         * @param sShopName
         * @returns {string}
         */
        getErrorMessage(sOS, sShopName) {
            let sErrorMessage = "";

            if (sOS === "iOS") {
                sErrorMessage = this.sIosDisabledMessage.replace("{$쇼핑몰명}", sShopName);
            } else {
                sErrorMessage = this.sAndroidDisabledMessage.replace("{$쇼핑몰명}", sShopName);
            }

            return sErrorMessage;
        }

        /**
         * Method for closing the window
         */
        closeWindow() {
            /**
             * window.close() is for tabs that was opened by JavaScript
             * This function will fail if the user was the one who opened the tab
             */
            window.close();
            /**
             * history.go() is a fallback function in case window.close did not execute.
             * window.close() will fail if the user opened a tab himself / herself.
             */
            history.go(-1);
        }

        /**
         * Check if the app is supported
         * @return {boolean}
         */
        isAppDownloadUnsupportedAgent() {
            if (this.helpers.isAndroid() || this.helpers.isIos()) {
                let agentList = this.appDownloadUnavailableAgent.join("|");
                let pattern = new RegExp(agentList, "i");
                return pattern.test(this.helpers.userAgent);
            }
            return false;
        }

        isDesktopBrowser() {
            if (this.helpers.isAndroid() || this.helpers.isIos()) {
                return false;
            }
            return true;
        }

        /**
         * Method to detect if safari browser
         * Based on https://stackoverflow.com/questions/9847580/how-to-detect-safari-chrome-ie-firefox-and-opera-browsers
         * @returns {boolean | *}
         */
        isDesktopSafari() {
            return (
                /constructor/i.test(window.HTMLElement) ||
                (function (p) {
                    return p.toString() === "[object SafariRemoteNotification]";
                })(!window["safari"] || (typeof safari !== "undefined" && window["safari"].pushNotification))
            );
        }
    }

    new AppDownload();
});
